import React from "react"
import InstaLogo from "./InstaLogo"
import LogoRight from "./LogoRight"
import "./Title.css"

const Title = () => (
    <div className="title">
        <InstaLogo />
        <a className="name" href="http://instagram.com/pop.top.van">Adventures in a Pop Top Van</a>
        <div className="logo-right"><LogoRight /></div>
    </div>
)

export default Title