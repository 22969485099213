import React, { Component } from "react"
// import InstaFeed from "./insta/EmbededInstaFeed"
import InstaFeed from "./insta/CuratorInstaFeed"
import Title from "./title/Title"
import "./App.css"

class App extends Component {
  render() {
    return (
      <div className="content">
        <Title />
        <div className="feed">
          <InstaFeed/>
        </div>
      </div>
    )
  }
}

export default App
